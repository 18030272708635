import type { WorkspaceType } from "@asap/shared";
import { createSharedComposable } from "@vueuse/core";
import { useManagees } from "~/composables/useManagees";
import { teamMemberColumns, workspaceColumns } from "~/utils/supabase.columns";
import type { TeamMember, Workspace } from "~/utils/supabase.types";
import { ASAP_WORKSPACE_ID } from "~/utils/workspace";

export const useWorkspaceMe = createSharedComposable(function () {
  const teamMembers = ref<TeamMember[]>([]);
  const workspace = ref<Workspace>();

  const supabase = useSupabase();
  const { workspaceId, isSupportRole, isDeveloper, invitedWorkspaces, isIndependent } = useUserMe();
  const { areSomeManageesIndependent } = useManagees();

  const isAsapWorkspace = computed<boolean>(() => workspaceId.value === ASAP_WORKSPACE_ID);
  const isIndependentWorkspace = computed<boolean>(() => !isAsapWorkspace.value);

  // I'm scoped to my workspace unless
  // - I'm support/dev role.
  // - I'm ASAP, and a referent or a manager of a referent.
  // - I'm an independent and I've been invited to an "external" workspace.
  const canSwitchWorkspace = computed<boolean>(() => {
    if (isSupportRole.value || isDeveloper.value) return true;
    if (isAsapWorkspace.value && areSomeManageesIndependent.value) return true;
    if (isIndependent.value && invitedWorkspaces.value && invitedWorkspaces.value.length > 0) return true;
    return false;
  });

  const getWorkspace = async () => {
    if (!workspaceId.value) return;

    const { data, error } = await supabase
      .from("workspace")
      .select(workspaceColumns)
      .eq("id", workspaceId.value)
      .single();

    if (error) throw error;

    workspace.value = data;
  };

  const getTeamMembers = async () => {
    if (!workspaceId.value) return;

    const { data: memberships, error: errorMemberships } = await supabase
      .from("team_member_workspace")
      .select()
      .eq("workspace_id", workspaceId.value);
    if (errorMemberships) throw errorMemberships;

    const { data, error } = await supabase
      .from("team_member")
      .select(teamMemberColumns)
      .in(
        "id",
        memberships.map((membership) => membership.team_member_id)
      );
    if (error) throw error;

    teamMembers.value = data;
  };

  onMounted(async () => {
    await getWorkspace();
    await getTeamMembers();
  });

  return {
    workspace,
    teamMembers,
    isAsapWorkspace,
    isIndependentWorkspace,
    canSwitchWorkspace,
  };
});

export const useWorkspaceFilter = createSharedComposable(() => {
  const workspaceType = ref<WorkspaceType>("internal");

  return {
    workspaceType,
  };
});
