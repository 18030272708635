<template>
  <button v-bind="$attrs" class="button" :class="[variant, size, { isRounded }]">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import type { ButtonProps } from "./types";

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: "primary",
  size: "medium",
  isRounded: false,
});

provide("buttonVariant", props.variant);
</script>

<style scoped lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  transition: var(--transition-1);
  text-wrap: nowrap;
  flex-shrink: 0;

  &.primary {
    color: var(--white);
    border: 1px solid var(--primary-dark);
    background: var(--primary);
    box-shadow: var(--shadow-1);

    &:hover {
      background: var(--primary-dark);
    }

    &:active {
      background: var(--primary-darker);
    }

    &:focus {
      border: 1px solid var(--primary-dark);
      outline: 4px solid var(--primary-light);
    }

    &:disabled {
      background: var(--primary-light);
      border: 1px solid var(--primary-light);
    }
  }

  &.blue {
    color: var(--white);
    border: none;
    background: var(--blue);

    &:hover {
      background: var(--blue-dark);
    }

    &:active {
      background: var(--blue-darker);
    }

    &:focus {
      border: 1px solid var(--blue-dark);
      outline: 4px solid var(--blue-light);
    }

    &:disabled {
      background: var(--primary-light);
      border: 1px solid var(--primary-light);
    }
  }

  &.outline,
  &.outline-set-item {
    color: var(--gray-2);
    border: 1px solid var(--border);
    background: var(--white);
    box-shadow: var(--shadow-1);

    &:hover {
      border: 1px solid var(--border-hover);
    }

    &:focus {
      border-color: var(--border-focus);
      outline: 1px solid var(--border-focus-outline);
    }

    &:disabled {
      background: var(--surface-disabled);

      &:hover {
        border-color: var(--border);
      }
    }
  }

  &.outline-set-item {
    padding: 1px;

    &.small {
      border-radius: initial;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    &:not(:first-of-type) {
      margin-left: -1px;
    }
  }

  &.danger {
    color: var(--white);
    border: 1px solid var(--danger-dark);
    background: var(--danger);
    box-shadow: var(--shadow-1);

    &:hover {
      background: var(--danger-dark);
    }

    &:active {
      background: var(--danger-darker);
    }

    &:focus {
      border: 1px solid var(--danger-dark);
      outline: 4px solid var(--danger-light);
    }

    &:disabled {
      background: var(--danger-light);
      border: 1px solid var(--danger-light);
    }
  }

  &.success {
    color: var(--white);
    border: 1px solid var(--success-dark);
    background: var(--success);
    box-shadow: var(--shadow-1);

    &:hover {
      background: var(--success-dark);
    }

    &:active {
      background: var(--success-darker);
    }

    &:focus {
      border: 1px solid var(--success-dark);
      outline: 4px solid var(--success-light);
    }

    &:disabled {
      background: var(--success-light);
      border: 1px solid var(--success-light);
    }
  }

  &.ghost {
    color: var(--gray-3);

    &:hover {
      &:not(:disabled) {
        background: var(--gray-6);
      }
    }
  }

  &.ghost-link {
    color: var(--blue);
    justify-content: flex-start;

    &:hover {
      &:not(:disabled) {
        background: var(--blue-lighter);
      }
    }

    &:active {
      &:not(:disabled) {
        background: var(--blue-light);
      }
    }

    &:focus {
      border: 1px solid var(--blue);
      outline: 2px solid var(--blue-light);
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.small {
    height: 32px;
    padding-inline: 8px;
    border-radius: 8px;
  }

  &.medium {
    min-height: 40px;
    padding-inline: 12px;
  }

  &.large {
    height: 48px;
    padding-inline: 12px;
  }

  &.xlarge {
    height: 56px;
    padding-inline: 12px;
  }

  &.icon {
    height: 40px;
    width: 40px;
  }

  &.icon-small {
    height: 32px;
    width: 32px;
  }

  &.isRounded {
    border-radius: 100px;
  }
}
</style>
